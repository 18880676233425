<template>
    <div class="page-wrap" id="scrollPage" @scroll="handleScroll" style="height: 100vh;overflow: auto;scroll-behavior: smooth;">
        <Header @gotoFAQ="gotoFAQ" @gotoPricing="gotoPricing" @playAnimation="playAnimation($event,'arrow_right_line')"></Header>
        <div class="banner">
            <div class="banner-bg-wrap">
                <div class="banner-row" v-for="(RowItem, index) in bannerIcons" :key="index">
                    <div class="banner-group" v-for="(GroupItem, index) in RowItem" :key="index">
                        <div class="item" v-for="(item, index) in GroupItem" :key="index">
                            <img :src="domainName +'/images/SVG/sharp/' + item + '.svg'" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-text-wrap">
                <div class="banner-tips">
                    <a href="https://store.mingcute.com/buy/3099f1cf-b0dc-4494-b739-8a1517c5035d" target="_blank" class="tips"><em class="num">NEW</em><span>Enhanced SVG Icon Formatting</span></a>
                </div>
                <div class="title"><span>Carefully crafted icon solution for you</span></div>
                <div class="info">MGC Icon System is a comprehensive collection of over 14,000 high-quality vector icons across nine styles: cute light, cute regular, cute filled, sharp, light, regular, filled, duotone, and two-tone.</div>
                <div class="info-formobile">MGC Icon System is a comprehensive collection of
over 14,000 high-quality vector icons across nine
styles: cute light, cute regular, cute filled, sharp, 
light, regular, filled, duotone, and two-tone. </div>
                <div class="action">
                    <a href="https://store.mingcute.com/buy/3099f1cf-b0dc-4494-b739-8a1517c5035d" target="_blank" class="button btn-white box-arrow_right_line" @mouseenter.native="playAnimation($event,'arrow_right_line')"><span>Buy now</span><i id="lottieOther-arrow_right_line" class="lottieOther-arrow_right_line arrow"></i></a>
                    <a href="https://www.figma.com/community/file/1356275486390593802" target="_blank" class="button box-arrow_right_line" @mouseenter.native="playAnimation($event,'arrow_right_line')"><span>Preview</span><i id="lottieOther-arrow_right_line" class="lottieOther-arrow_right_line arrow"></i></a>
                </div>
            </div>
        </div>
        <div class="drag-pic-section">
            <div class="drag-pic-wrap" ref="targetElement" :style="{transform: 'perspective(1200px) scale(1) rotate(0deg) rotateX(' + rotateAngle +'deg) rotateY(0deg)'}">
                <div class="bg"></div>
                <div class="pic-wrap">
                    <div class="main-pic"></div>
                    <div class="one-card" :style="{transform: 'translateY(' + translateY + 'px)'}"></div>
                    <div class="two-card" :style="{transform: 'translateY(' + translateY + 'px)'}"></div>
                    <div class="three-card" :style="{transform: 'translateY(' + translateY + 'px)'}"></div>
                </div>
            </div>
        </div>
        <!-- styles -->
        <div class="mcs-section multiple-styles-wrap">
            <div class="mc-top"><span>Styles</span></div>
            <div class="title">Multiple Styles</div>
            <div class="describe">You can easily switch icons between 9 styles using Figma variants and choose the perfect icon to match your design preferences. More styles are coming soon.</div>
            <div class="styles-wrap">
                <div class="styles-scroll">
                    <div class="type-wrap">
                        <div class="item" v-for="(item, index) in types" :key="index" :class="{'current': currentTypes === item.name}" @click="toggleStyles(index, item.name)"><span>{{ item.name }}</span><s v-if="item.isNew" class="mark">New</s></div>
                    </div>
                    <div class="sample-wrap">
                        <div class="item" v-for="index in 9" :key="index" :class="{'current': currentSampleIndex === index - 1}"></div>
                    </div>
                </div>
                <div class="icons-window">
                    <div class="icons-wrap" :style="{ transform: 'translateX(calc(-100% * ' + this.currentIconsIndex + '))' }">
                        <div class="icons-item" v-for="(iconsItem, index) in stylesIcons" :key="index">
                            <div class="item" v-for="(item, index) in iconsItem.array" :key="index"><img :src="domainName + '/images/SVG/' + iconsItem.name + '/' + item + '.svg' " alt=""></div>
                        </div>
                        <div class="icons-item"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mcs-section">
            <div class="mc-top"><span>Grid</span></div>
            <div class="title">Consistent Design Principles</div>
            <div class="describe">Every icon is designed within a 24 x 24 grid, and has a 2px padding/safe area. Create new icons by using Boolean operations on basic shapes. </div>
            <div class="grid-wrap-dress">
                <div class="grid-wrap">
                    <div class="item">
                        <i></i>
                    </div>
                    <div class="item">
                        <i></i>
                    </div>
                    <div class="item">
                        <i></i>
                    </div>
                    <div class="item">
                        <i></i>
                    </div>
                    <div class="item bigger">
                        <i></i>
                    </div>
                    <div class="item bigger">
                        <i></i>
                    </div>
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item"></div>
                    <div class="item"></div>
                </div>
            </div>
        </div>
        <!-- Flexibility -->
        <div class="mcs-section">
            <div class="mc-top"><span>Flexibility</span></div>
            <div class="title">Customize With Full Control</div>
            <div class="describe">All the icons are vector-based and can be easily customized using source files. Easily change stroke weight or corner radius of any icon.</div>
            <div class="flexibility-wrap">
                <div class="flexibility-top">
                    <div class="item">
                        <div class="cont">
                            <div class="title">New Cute styles</div>
                            <div class="info">Featuring impeccably smoothed corners at 100%, resulting in smoother, rounder edges that exemplify the unique super-ellipse design style.</div>
                        </div>
                        <div class="pic"></div>
                    </div>
                    <div class="item">
                        <div class="cont">
                            <div class="title">Clean and tidy single vector layer</div>
                            <div class="info">We merge paths into a clean single layer, while retaining the features of live corners and editable anchor points.</div>
                        </div>
                        <div class="pic"></div>
                    </div>
                    <div class="item">
                        <div class="pic"></div>
                        <div class="cont">
                            <div class="title">editable vector path with live corners</div>
                            <div class="info">The icons are drawn based on vector paths. Easily change corner radius or corner style.</div>
                        </div>
                    </div>
                </div>
                <div class="flexibility-bottom">
                    <div class="item">
                        <div class="cont">
                            <div class="title">Regularly updated</div>
                            <div class="info">We maintain the freshness of our icon set by updating it weekly to monthly, staying abreast of trends and new technologies.</div>
                            <a href="https://twitter.com/MingCute_icon" target="_blank" class="button"><i class="x"></i><span>Follow</span></a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="cont">
                            <div class="title">Lively and smooth animations</div>
                            <div class="info">We have launched the animation icons pack, which is a meticulously designed library of animated icons featuring lifelike lottie animations. The animation pack  is not included in the MGC icon system.</div>
                            <a href="https://www.mingcute.com/animation" target="_blank" class="button box-arrow_right_line" @mouseenter.native="playAnimation($event,'arrow_right_line')"><span>Learn more</span><i id="lottieOther-arrow_right_line" class="lottieOther-arrow_right_line arrow"></i></a>
                            <div class="icons-wrap">
                                <div v-for="(item, index) in flexLottie" :key="index" :class="'icon'" @mouseenter="playIndex(item, index)"><i :id="'flexLottie-' + item.name" :class="'flexLottie-' + item.name"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mcs-section">
            <div class="mc-top"><span>Format</span></div>
            <div class="title">Well organized & structured file</div>
            <div class="describe">The icons have been exported in different formats. Available in a variety of formats, including Figma, SVG, PNG, and PDF format</div>
            <div class="format-wrap">
                <div class="format-icons">
                    <i class="icon-format if-figma"></i>
                    <i class="icon-format if-svg"></i>
                    <i class="icon-format if-png"></i>
                    <i class="icon-format if-pdf"></i>
                </div>
                <div class="format-pic">
                    <div class="pic"></div>
                </div>
            </div>
        </div>
        <div class="mcs-section">
            <div class="mc-top"><span>Examples</span></div>
            <div class="title">Perfect for your projects</div>
            <div class="describe">Our icons have a wide range of applications and diverse usage scenarios, including various websites, applications, social networks, and prints.</div>
            <div class="examples-wrap">
                <div class="list-item">
                    <div class="pic"></div>
                </div>
                <div class="list-item">
                    <div class="pic">
                        <i id="lottieOther-voice_line" class="lottieOther-voice_line icon-voice"></i>
                    </div>
                    <div class="pic"></div>
                    <div class="pic"></div>
                    <div class="pic"></div>
                </div>
                <div class="list-item">
                    <div class="pic">
                        <i id="lottieOther-sun_line" class="lottieOther-sun_line icon-sun"></i>
                    </div>
                    <div class="pic"></div>
                </div>
            </div>
        </div>
        <div class="mcs-section">
            <div class="mc-top"><span>Reviews</span></div>
            <div class="title">What do people say about the icons?</div>
            <div class="describe">Trusted by many users around the world</div>
            <div class="reviews-wrap">
                <div class="list-item">
                    <div class="item">
                        <div class="info">Love these! MingCute looks like a great set!</div>
                        <div class="head-info">
                            <div class="pic"><img src="../assets/face/face1.png" alt=""></div>
                            <div class="cont">
                                <div class="name">Liam McCabe</div>
                                <div class="desc">Founder at Layers</div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="info">Simple and beautifully-crafted library from @MingCute_icon</div>
                        <div class="head-info">
                            <div class="pic"><img src="../assets/face/face4.png" alt=""></div>
                            <div class="cont">
                                <div class="name">Jordan Hughes</div>
                                <div class="desc">product designer</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-item">
                    <div class="item">
                        <div class="info">Discovered a nicely crafted icon collection 💯 @MingCute_icon. Yeah many great icons.</div>
                        <div class="head-info">
                            <div class="pic"><img src="../assets/face/face2.png" alt=""></div>
                            <div class="cont">
                                <div class="name">Jim Raptis</div>
                                <div class="desc">Developer. Designer.</div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="info">これでUIデザイン用のSVGアイコンに困らない！このアイコンの特徴は、小さいサイズでもくっきり見えること。</div>
                        <div class="head-info">
                            <div class="pic"><img src="../assets/face/face5.png" alt=""></div>
                            <div class="cont">
                                <div class="name">コリス</div>
                                <div class="desc">Design media</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-item">
                    <div class="item">
                        <div class="info">I love @MingCute_icon. They're curvy but not too curvy.</div>
                        <div class="head-info">
                            <div class="pic"><img src="../assets/face/face3.png" alt=""></div>
                            <div class="cont">
                                <div class="name">Nabeel</div>
                                <div class="desc">Product designer</div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="info">A collection of my favorite icon libraries for designing user interfaces</div>
                        <div class="head-info">
                            <div class="pic"><img src="../assets/face/face6.png" alt=""></div>
                            <div class="cont">
                                <div class="name">Dmitriy Bunin</div>
                                <div class="desc">Product Designer</div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="mcs-section" id="pricing-wrap">
            <div class="mc-top"><span>Pricing</span></div>
            <div class="title">Simple, transparent pricing</div>
            <div class="describe">Buy once, Use forever, lifetime updates, Unlimited projects.</div>
            <div class="pricing-wrap">
                <div class="item">
                    <i class="icon-single"></i>
                    <div class="top">
                        <div class="name">Single</div>
                        <div class="price"><s>$</s><span>59</span></div>
                    </div>
                    <div class="info">For solo designers, developers,  and freelancers.</div>
                    <a href="https://store.mingcute.com/buy/3099f1cf-b0dc-4494-b739-8a1517c5035d" class="button box-arrow_right_line" @mouseenter.native="playAnimation($event,'arrow_right_line')"><span>Buy Single</span><i id="lottieOther-arrow_right_line" class="lottieOther-arrow_right_line arrow"></i></a>
                    <ul>
                        <li>
                            <i></i>
                            <span>Single user license</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Nine distinct styles</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Figma, SVG, PNG, and PDF</span>
                        </li>
                        <li>
                            <i></i>
                            <span>26 categories</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Access to full Icon system</span>
                        </li>
                        <li>
                            <i></i>
                            <span>14,000+ icons and growing</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Use on unlimited projects</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Free lifetime updates</span>
                        </li>
                    </ul>
                </div>
                <div class="item">
                    <i class="icon-team"></i>
                    <div class="top">
                        <div class="name">Team</div>
                        <div class="price"><s>$</s><span>129</span></div>
                    </div>
                    <div class="info">For startup, design teams & agencies up to 5 users </div>
                    <a href="https://store.mingcute.com/buy/db872f30-ed9a-4d95-abcd-86e087c085b6" class="button btn-white box-arrow_right_line" @mouseenter.native="playAnimation($event,'arrow_right_line')"><span>Buy Team</span><i id="lottieOther-arrow_right_line" class="lottieOther-arrow_right_line arrow"></i></a>
                    <ul>
                        <li>
                            <i></i>
                            <span>Up to 5 users license</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Nine distinct styles</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Figma, SVG, PNG, and PDF</span>
                        </li>
                        <li>
                            <i></i>
                            <span>26 categories</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Access to full Icon system</span>
                        </li>
                        <li>
                            <i></i>
                            <span>14,000+ icons and growing</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Use on unlimited projects</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Free lifetime updates</span>
                        </li>
                    </ul>
                </div>
                <div class="item">
                    <i class="icon-enterprise"></i>
                    <div class="top">
                        <div class="name">Enterprise</div>
                        <div class="price"><s>$</s><span>349</span></div>
                    </div>
                    <div class="info">For Large enterprises, teams,  and companies, unlimited users</div>
                    <a href="https://store.mingcute.com/buy/89f61f7a-c914-410b-8d4a-30ec588c4957" class="button box-arrow_right_line" @mouseenter.native="playAnimation($event,'arrow_right_line')"><span>Buy Enterprise</span><i id="lottieOther-arrow_right_line" class="lottieOther-arrow_right_line arrow"></i></a>
                    <ul>
                        <li>
                            <i></i>
                            <span>Unlimited users license</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Nine distinct styles</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Figma, SVG, PNG, and PDF</span>
                        </li>
                        <li>
                            <i></i>
                            <span>26 categories</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Access to full Icon system</span>
                        </li>
                        <li>
                            <i></i>
                            <span>14,000+ icons and growing</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Use on unlimited projects</span>
                        </li>
                        <li>
                            <i></i>
                            <span>Free lifetime updates</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mcs-section" id="consult-wrap">
            <div class="mc-top"><span>FAQs</span></div>
            <div class="title">Frequently asked questions</div>
            <div class="describe">Need help with something?The section below will tell you how.</div>
            <div class="consult-question">
                <div class="question-cell" v-for="(item, index) in quesionAnswer" :key="index" @click="toggleQ(index)">
                    <div class="question">
                        <span class="name" v-html="item.question"></span><i class="icon-down" :class="{'opened': item.isOpenQ}"></i>
                    </div>
                    <div class="answer" :class="{'opened': item.isOpenQ}" v-html="item.answer"></div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

import Header from "./common/Header.vue";
import Footer from "./common/Footer.vue";

import lottie from 'lottie-web';

export default defineComponent({
    components: {
        Header,
        Footer
    },
    data() {
        return {
            domainName: window.location.origin,
            bannerIcons: [
                [
                [
                        'device/pad_sharp',
                        'arrow/fullscreen_exit_2_sharp',
                        'device/display_sharp',
                        'device/bulb_2_sharp',
                        'arrow/transfer_horizontal_sharp',
                        'education/book_6_sharp',
                        'education/black_board_2_sharp',
                        'file/pin_sharp',
                        'design/layout_2_sharp',
                        'contact/mail_sharp',
                        'design/exclude_sharp',
                        'business/copper_coin_sharp',
                        'business/wallet_sharp',
                        'business/bank_card_sharp',
                        'business/coin_2_sharp',
                        'development/cursor_2_sharp',
                        'business/chart_bar_sharp',
                        'business/stock_sharp',
                        'business/chart_line_sharp',
                        'business/chart_pie_sharp',
                    ],
                    [
                        'device/pad_sharp',
                        'arrow/fullscreen_exit_2_sharp',
                        'device/display_sharp',
                        'device/bulb_2_sharp',
                        'arrow/transfer_horizontal_sharp',
                        'education/book_6_sharp',
                        'education/black_board_2_sharp',
                        'file/pin_sharp',
                        'design/layout_2_sharp',
                        'contact/mail_sharp',
                        'design/exclude_sharp',
                        'business/copper_coin_sharp',
                        'business/wallet_sharp',
                        'business/bank_card_sharp',
                        'business/coin_2_sharp',
                        'development/cursor_2_sharp',
                        'business/chart_bar_sharp',
                        'business/stock_sharp',
                        'business/chart_line_sharp',
                        'business/chart_pie_sharp',
                    ],
                ],
                [
                [
                        'business/ticket_sharp',
                        'file/upload_sharp',
                        'file/doc_sharp',
                        'business/shopping_bag_2_sharp',
                        'business/shopping_cart_1_sharp',
                        'file/news_2_sharp',
                        'business/briefcase_sharp',
                        'media/camera_2_sharp',
                        'business/presentation_2_sharp',
                        'business/calendar_sharp',
                        'business/filter_sharp',
                        'system/lock_sharp',
                        'shape/triangle_sharp',
                        'business/schedule_sharp',
                        'business/trophy_sharp',
                        'business/flag_3_sharp',
                        'business/tag_sharp',
                        'business/basket_sharp',
                        'business/gift_sharp',
                        'business/wallet_2_sharp',
                    ],
                    [
                        'business/ticket_sharp',
                        'file/upload_sharp',
                        'file/doc_sharp',
                        'business/shopping_bag_2_sharp',
                        'business/shopping_cart_1_sharp',
                        'file/news_2_sharp',
                        'business/briefcase_sharp',
                        'media/camera_2_sharp',
                        'business/presentation_2_sharp',
                        'business/calendar_sharp',
                        'business/filter_sharp',
                        'system/lock_sharp',
                        'shape/triangle_sharp',
                        'business/schedule_sharp',
                        'business/trophy_sharp',
                        'business/flag_3_sharp',
                        'business/tag_sharp',
                        'business/basket_sharp',
                        'business/gift_sharp',
                        'business/wallet_2_sharp',
                    ],
                ],
                [
                [
                        'business/red_packet_sharp',
                        'file/inbox_sharp',
                        'business/copyright_sharp',
                        'media/camcorder_sharp',
                        'media/notification_sharp',
                        'building/store_sharp',
                        'business/safe_box_sharp',
                        'file/task_2_sharp',
                        'business/VIP_2_sharp',
                        'file/report_forms_sharp',
                        'business/VIP_4_sharp',
                        'editor/edit_sharp',
                        'business/award_sharp',
                        'business/medal_sharp',
                        'business/luggage_sharp',
                        'business/wallet_3_sharp',
                        'business/receive_money_sharp',
                        'business/wallet_4_sharp',
                        'business/target_sharp',
                        'business/seal_sharp',
                    ],
                    [
                        'business/red_packet_sharp',
                        'file/inbox_sharp',
                        'business/copyright_sharp',
                        'media/camcorder_sharp',
                        'media/notification_sharp',
                        'building/store_sharp',
                        'business/safe_box_sharp',
                        'file/task_2_sharp',
                        'business/VIP_2_sharp',
                        'file/report_forms_sharp',
                        'business/VIP_4_sharp',
                        'editor/edit_sharp',
                        'business/award_sharp',
                        'business/medal_sharp',
                        'business/luggage_sharp',
                        'business/wallet_3_sharp',
                        'business/receive_money_sharp',
                        'business/wallet_4_sharp',
                        'business/target_sharp',
                        'business/seal_sharp',
                    ],
                ],
            ],
            stylesIcons: [
            {
                    'name': 'cute light',
                    'array': [
                        'building/home_2_cute_li',
                        'file/file_cute_li',
                        'file/folder_cute_li',
                        'business/calendar_cute_li',
                        'business/palette_3_cute_li',
                        'building/building_2_cute_li',
                        'business/wallet_3_cute_li',
                        'business/target_cute_li',
                        'design/bling_cute_li',
                        'design/pen_cute_li',
                        'shape/heart_cute_li',
                        'user/user_3_cute_li',
                        'shape/clubs_cute_li',
                        'weather/tornado_cute_li',

                        'part/finger_tap_cute_li',
                        'building/store_cute_li',
                        'building/sydney_opera_house_cute_li',
                        'part/dental_cute_li',
                        'business/presentation_2_cute_li',
                        'transport/flight_takeoff_cute_li',
                        'business/filter_3_cute_li',
                        'sport/badminton_cute_li',
                        'business/shopping_cart_1_cute_li',
                        'design/quill_pen_cute_li',
                        'building/hotel_cute_li',
                        'sport/fitness_cute_li',
                        'transport/rocket_cute_li',
                        'system/flame_cute_li',

                        'business/chart_decrease_cute_li',
                        'business/chart_pie_2_cute_li',
                        'business/wallet_3_cute_li',
                        'business/chart_bar_cute_li',
                        'system/settings_7_cute_li',
                        'business/coin_2_cute_li',
                        'business/ticket_cute_li',
                        'contact/chat_3_cute_li',
                        'business/currency_dollar_cute_li',
                        'business/gift_cute_li',
                        'contact/mailbox_cute_li',
                        'contact/phone_outgoing_cute_li',
                        'contact/send_plane_cute_li',
                        'contact/mail_open_cute_li',

                        'crypto/ethereum_cute_li',
                        'crypto/monero_cute_li',
                        'design/board_cute_li',
                        'design/brush_2_cute_li',
                        'design/drop_cute_li',
                        'design/layout_3_cute_li',
                        'design/magic_3_cute_li',
                        'design/paint_2_cute_li',
                        'design/scale_cute_li',
                        'design/scissors_2_cute_li',
                        'development/cursor_3_cute_li',
                        'development/department_cute_li',
                        'device/airplay_cute_li',
                        'device/alarm_2_cute_li',

                        'device/dashboard_cute_li',
                        'device/device_cute_li',
                        'device/homepod_cute_li',
                        'weather/snowflake_cute_li',
                        'device/vison_pro_cute_li',
                        'editor/at_cute_li',
                        'editor/pencil_2_cute_li',
                        'editor/translate_2_cute_li',
                        'education/mortarboard_cute_li',
                        'education/black_board_2_cute_li',
                        'emoji/puzzled_cute_li',
                        'emoji/tongue_cute_li',
                        'file/box_3_cute_li',
                        'file/file_certificate_cute_li',
                        
                        'file/inbox_2_cute_li',
                        'file/link_3_cute_li',
                        'file/pic_cute_li',
                        'file/task_2_cute_li',
                        'food/birthday_2_cute_li',
                        'food/fries_cute_li',
                        'food/lollipop_cute_li',
                        'map/location_3_cute_li',
                        'map/planet_cute_li',
                        'nature/chess_cute_li',
                        'nature/tree_cute_li',
                        'nature/flower_4_cute_li',
                        'other/capsule_cute_li',
                        'other/hand_card_cute_li',
                    ]
                },
            {
                    'name': 'cute regular',
                    'array': [
                        'building/home_2_cute_re',
                        'file/file_cute_re',
                        'file/folder_cute_re',
                        'business/calendar_cute_re',
                        'business/palette_3_cute_re',
                        'building/building_2_cute_re',
                        'business/wallet_3_cute_re',
                        'business/target_cute_re',
                        'design/bling_cute_re',
                        'design/pen_cute_re',
                        'shape/heart_cute_re',
                        'user/user_3_cute_re',
                        'shape/clubs_cute_re',
                        'weather/tornado_cute_re',

                        'part/finger_tap_cute_re',
                        'building/store_cute_re',
                        'building/sydney_opera_house_cute_re',
                        'part/dental_cute_re',
                        'business/presentation_2_cute_re',
                        'transport/flight_takeoff_cute_re',
                        'business/filter_3_cute_re',
                        'sport/badminton_cute_re',
                        'business/shopping_cart_1_cute_re',
                        'design/quill_pen_cute_re',
                        'building/hotel_cute_re',
                        'sport/fitness_cute_re',
                        'transport/rocket_cute_re',
                        'system/flame_cute_re',

                        'business/chart_decrease_cute_re',
                        'business/chart_pie_2_cute_re',
                        'business/wallet_3_cute_re',
                        'business/chart_bar_cute_re',
                        'system/settings_7_cute_re',
                        'business/coin_2_cute_re',
                        'business/ticket_cute_re',
                        'contact/chat_3_cute_re',
                        'business/currency_dollar_cute_re',
                        'business/gift_cute_re',
                        'contact/mailbox_cute_re',
                        'contact/phone_outgoing_cute_re',
                        'contact/send_plane_cute_re',
                        'contact/mail_open_cute_re',

                        'crypto/ethereum_cute_re',
                        'crypto/monero_cute_re',
                        'design/board_cute_re',
                        'design/brush_2_cute_re',
                        'design/drop_cute_re',
                        'design/layout_3_cute_re',
                        'design/magic_3_cute_re',
                        'design/paint_2_cute_re',
                        'design/scale_cute_re',
                        'design/scissors_2_cute_re',
                        'development/cursor_3_cute_re',
                        'development/department_cute_re',
                        'device/airplay_cute_re',
                        'device/alarm_2_cute_re',

                        'device/dashboard_cute_re',
                        'device/device_cute_re',
                        'device/homepod_cute_re',
                        'weather/snowflake_cute_re',
                        'device/vison_pro_cute_re',
                        'editor/at_cute_re',
                        'editor/pencil_2_cute_re',
                        'editor/translate_2_cute_re',
                        'education/mortarboard_cute_re',
                        'education/black_board_2_cute_re',
                        'emoji/puzzled_cute_re',
                        'emoji/tongue_cute_re',
                        'file/box_3_cute_re',
                        'file/file_certificate_cute_re',
                        
                        'file/inbox_2_cute_re',
                        'file/link_3_cute_re',
                        'file/pic_cute_re',
                        'file/task_2_cute_re',
                        'food/birthday_2_cute_re',
                        'food/fries_cute_re',
                        'food/lollipop_cute_re',
                        'map/location_3_cute_re',
                        'map/planet_cute_re',
                        'nature/chess_cute_re',
                        'nature/tree_cute_re',
                        'nature/flower_4_cute_re',
                        'other/capsule_cute_re',
                        'other/hand_card_cute_re',
                    ]
                },
            {
                    'name': 'cute filled',
                    'array': [
                        'building/home_2_cute_fi',
                        'file/file_cute_fi',
                        'file/folder_cute_fi',
                        'business/calendar_cute_fi',
                        'business/palette_3_cute_fi',
                        'building/building_2_cute_fi',
                        'business/wallet_3_cute_fi',
                        'business/target_cute_fi',
                        'design/bling_cute_fi',
                        'design/pen_cute_fi',
                        'shape/heart_cute_fi',
                        'user/user_3_cute_fi',
                        'shape/clubs_cute_fi',
                        'weather/tornado_cute_fi',

                        'part/finger_tap_cute_fi',
                        'building/store_cute_fi',
                        'building/sydney_opera_house_cute_fi',
                        'part/dental_cute_fi',
                        'business/presentation_2_cute_fi',
                        'transport/flight_takeoff_cute_fi',
                        'business/filter_3_cute_fi',
                        'sport/badminton_cute_fi',
                        'business/shopping_cart_1_cute_fi',
                        'design/quill_pen_cute_fi',
                        'building/hotel_cute_fi',
                        'sport/fitness_cute_fi',
                        'transport/rocket_cute_fi',
                        'system/flame_cute_fi',

                        'business/chart_decrease_cute_fi',
                        'business/chart_pie_2_cute_fi',
                        'business/wallet_3_cute_fi',
                        'business/chart_bar_cute_fi',
                        'system/settings_7_cute_fi',
                        'business/coin_2_cute_fi',
                        'business/ticket_cute_fi',
                        'contact/chat_3_cute_fi',
                        'business/currency_dollar_cute_fi',
                        'business/gift_cute_fi',
                        'contact/mailbox_cute_fi',
                        'contact/phone_outgoing_cute_fi',
                        'contact/send_plane_cute_fi',
                        'contact/mail_open_cute_fi',

                        'crypto/ethereum_cute_fi',
                        'crypto/monero_cute_fi',
                        'design/board_cute_fi',
                        'design/brush_2_cute_fi',
                        'design/drop_cute_fi',
                        'design/layout_3_cute_fi',
                        'design/magic_3_cute_fi',
                        'design/paint_2_cute_fi',
                        'design/scale_cute_fi',
                        'design/scissors_2_cute_fi',
                        'development/cursor_3_cute_fi',
                        'development/department_cute_fi',
                        'device/airplay_cute_fi',
                        'device/alarm_2_cute_fi',

                        'device/dashboard_cute_fi',
                        'device/device_cute_fi',
                        'device/homepod_cute_fi',
                        'weather/snowflake_cute_fi',
                        'device/vison_pro_cute_fi',
                        'editor/at_cute_fi',
                        'editor/pencil_2_cute_fi',
                        'editor/translate_2_cute_fi',
                        'education/mortarboard_cute_fi',
                        'education/black_board_2_cute_fi',
                        'emoji/puzzled_cute_fi',
                        'emoji/tongue_cute_fi',
                        'file/box_3_cute_fi',
                        'file/file_certificate_cute_fi',
                        
                        'file/inbox_2_cute_fi',
                        'file/link_3_cute_fi',
                        'file/pic_cute_fi',
                        'file/task_2_cute_fi',
                        'food/birthday_2_cute_fi',
                        'food/fries_cute_fi',
                        'food/lollipop_cute_fi',
                        'map/location_3_cute_fi',
                        'map/planet_cute_fi',
                        'nature/chess_cute_fi',
                        'nature/tree_cute_fi',
                        'nature/flower_4_cute_fi',
                        'other/capsule_cute_fi',
                        'other/hand_card_cute_fi',
                    ]
                },
                {
                    'name': 'sharp',
                    'array': [
                        'building/home_2_sharp',
                        'file/file_sharp',
                        'file/folder_sharp',
                        'business/calendar_sharp',
                        'business/palette_3_sharp',
                        'building/building_2_sharp',
                        'business/wallet_3_sharp',
                        'business/target_sharp',
                        'design/bling_sharp',
                        'design/pen_sharp',
                        'shape/heart_sharp',
                        'user/user_3_sharp',
                        'shape/clubs_sharp',
                        'weather/tornado_sharp',

                        'part/finger_tap_sharp',
                        'building/store_sharp',
                        'building/sydney_opera_house_sharp',
                        'part/dental_sharp',
                        'business/presentation_2_sharp',
                        'transport/flight_takeoff_sharp',
                        'business/filter_3_sharp',
                        'sport/badminton_sharp',
                        'business/shopping_cart_1_sharp',
                        'design/quill_pen_sharp',
                        'building/hotel_sharp',
                        'sport/fitness_sharp',
                        'transport/rocket_sharp',
                        'system/flame_sharp',

                        'business/chart_decrease_sharp',
                        'business/chart_pie_2_sharp',
                        'business/wallet_3_sharp',
                        'business/chart_bar_sharp',
                        'system/settings_7_sharp',
                        'business/coin_2_sharp',
                        'business/ticket_sharp',
                        'contact/chat_3_sharp',
                        'business/currency_dollar_sharp',
                        'business/gift_sharp',
                        'contact/mailbox_sharp',
                        'contact/phone_outgoing_sharp',
                        'contact/send_plane_sharp',
                        'contact/mail_open_sharp',

                        'crypto/ethereum_sharp',
                        'crypto/monero_sharp',
                        'design/board_sharp',
                        'design/brush_2_sharp',
                        'design/drop_sharp',
                        'design/layout_3_sharp',
                        'design/magic_3_sharp',
                        'design/paint_2_sharp',
                        'design/scale_sharp',
                        'design/scissors_2_sharp',
                        'development/cursor_3_sharp',
                        'development/department_sharp',
                        'device/airplay_sharp',
                        'device/alarm_2_sharp',

                        'device/dashboard_sharp',
                        'device/device_sharp',
                        'device/homepod_sharp',
                        'weather/snowflake_sharp',
                        'device/vison_pro_sharp',
                        'editor/at_sharp',
                        'editor/pencil_2_sharp',
                        'editor/translate_2_sharp',
                        'education/mortarboard_sharp',
                        'education/black_board_2_sharp',
                        'emoji/puzzled_sharp',
                        'emoji/tongue_sharp',
                        'file/box_3_sharp',
                        'file/file_certificate_sharp',
                        
                        'file/inbox_2_sharp',
                        'file/link_3_sharp',
                        'file/pic_sharp',
                        'file/task_2_sharp',
                        'food/birthday_2_sharp',
                        'food/fries_sharp',
                        'food/lollipop_sharp',
                        'map/location_3_sharp',
                        'map/planet_sharp',
                        'nature/chess_sharp',
                        'nature/tree_sharp',
                        'nature/flower_4_sharp',
                        'other/capsule_sharp',
                        'other/hand_card_sharp',
                    ]
                },
                {
                    'name': 'light',
                    'array': [
                        'building/home_2_light',
                        'file/file_light',
                        'file/folder_light',
                        'business/calendar_light',
                        'business/palette_3_light',
                        'building/building_2_light',
                        'business/wallet_3_light',
                        'business/target_light',
                        'design/bling_light',
                        'design/pen_light',
                        'shape/heart_light',
                        'user/user_3_light',
                        'shape/clubs_light',
                        'weather/tornado_light',

                        'part/finger_tap_light',
                        'building/store_light',
                        'building/sydney_opera_house_light',
                        'part/dental_light',
                        'business/presentation_2_light',
                        'transport/flight_takeoff_light',
                        'business/filter_3_light',
                        'sport/badminton_light',
                        'business/shopping_cart_1_light',
                        'design/quill_pen_light',
                        'building/hotel_light',
                        'sport/fitness_light',
                        'transport/rocket_light',
                        'system/flame_light',

                        'business/chart_decrease_light',
                        'business/chart_pie_2_light',
                        'business/wallet_3_light',
                        'business/chart_bar_light',
                        'system/settings_7_light',
                        'business/coin_2_light',
                        'business/ticket_light',
                        'contact/chat_3_light',
                        'business/currency_dollar_light',
                        'business/gift_light',
                        'contact/mailbox_light',
                        'contact/phone_outgoing_light',
                        'contact/send_plane_light',
                        'contact/mail_open_light',

                        'crypto/ethereum_light',
                        'crypto/monero_light',
                        'design/board_light',
                        'design/brush_2_light',
                        'design/drop_light',
                        'design/layout_3_light',
                        'design/magic_3_light',
                        'design/paint_2_light',
                        'design/scale_light',
                        'design/scissors_2_light',
                        'development/cursor_3_light',
                        'development/department_light',
                        'device/airplay_light',
                        'device/alarm_2_light',

                        'device/dashboard_light',
                        'device/device_light',
                        'device/homepod_light',
                        'weather/snowflake_light',
                        'device/vison_pro_light',
                        'editor/at_light',
                        'editor/pencil_2_light',
                        'editor/translate_2_light',
                        'education/mortarboard_light',
                        'education/black_board_2_light',
                        'emoji/puzzled_light',
                        'emoji/tongue_light',
                        'file/box_3_light',
                        'file/file_certificate_light',
                        
                        'file/inbox_2_light',
                        'file/link_3_light',
                        'file/pic_light',
                        'file/task_2_light',
                        'food/birthday_2_light',
                        'food/fries_light',
                        'food/lollipop_light',
                        'map/location_3_light',
                        'map/planet_light',
                        'nature/chess_light',
                        'nature/tree_light',
                        'nature/flower_4_light',
                        'other/capsule_light',
                        'other/hand_card_light',
                    ]
                },
                {
                    'name': 'regular',
                    'array': [
                    'building/home_2_regular',
                        'file/file_regular',
                        'file/folder_regular',
                        'business/calendar_regular',
                        'business/palette_3_regular',
                        'building/building_2_regular',
                        'business/wallet_3_regular',
                        'business/target_regular',
                        'design/bling_regular',
                        'design/pen_regular',
                        'shape/heart_regular',
                        'user/user_3_regular',
                        'shape/clubs_regular',
                        'weather/tornado_regular',

                        'part/finger_tap_regular',
                        'building/store_regular',
                        'building/sydney_opera_house_regular',
                        'part/dental_regular',
                        'business/presentation_2_regular',
                        'transport/flight_takeoff_regular',
                        'business/filter_3_regular',
                        'sport/badminton_regular',
                        'business/shopping_cart_1_regular',
                        'design/quill_pen_regular',
                        'building/hotel_regular',
                        'sport/fitness_regular',
                        'transport/rocket_regular',
                        'system/flame_regular',

                        'business/chart_decrease_regular',
                        'business/chart_pie_2_regular',
                        'business/wallet_3_regular',
                        'business/chart_bar_regular',
                        'system/settings_7_regular',
                        'business/coin_2_regular',
                        'business/ticket_regular',
                        'contact/chat_3_regular',
                        'business/currency_dollar_regular',
                        'business/gift_regular',
                        'contact/mailbox_regular',
                        'contact/phone_outgoing_regular',
                        'contact/send_plane_regular',
                        'contact/mail_open_regular',

                        'crypto/ethereum_regular',
                        'crypto/monero_regular',
                        'design/board_regular',
                        'design/brush_2_regular',
                        'design/drop_regular',
                        'design/layout_3_regular',
                        'design/magic_3_regular',
                        'design/paint_2_regular',
                        'design/scale_regular',
                        'design/scissors_2_regular',
                        'development/cursor_3_regular',
                        'development/department_regular',
                        'device/airplay_regular',
                        'device/alarm_2_regular',

                        'device/dashboard_regular',
                        'device/device_regular',
                        'device/homepod_regular',
                        'weather/snowflake_regular',
                        'device/vison_pro_regular',
                        'editor/at_regular',
                        'editor/pencil_2_regular',
                        'editor/translate_2_regular',
                        'education/mortarboard_regular',
                        'education/black_board_2_regular',
                        'emoji/puzzled_regular',
                        'emoji/tongue_regular',
                        'file/box_3_regular',
                        'file/file_certificate_regular',
                        
                        'file/inbox_2_regular',
                        'file/link_3_regular',
                        'file/pic_regular',
                        'file/task_2_regular',
                        'food/birthday_2_regular',
                        'food/fries_regular',
                        'food/lollipop_regular',
                        'map/location_3_regular',
                        'map/planet_regular',
                        'nature/chess_regular',
                        'nature/tree_regular',
                        'nature/flower_4_regular',
                        'other/capsule_regular',
                        'other/hand_card_regular',
                    ]
                },
                {
                    'name': 'filled',
                    'array': [
                    'building/home_2_filled',
                        'file/file_filled',
                        'file/folder_filled',
                        'business/calendar_filled',
                        'business/palette_3_filled',
                        'building/building_2_filled',
                        'business/wallet_3_filled',
                        'business/target_filled',
                        'design/bling_filled',
                        'design/pen_filled',
                        'shape/heart_filled',
                        'user/user_3_filled',
                        'shape/clubs_filled',
                        'weather/tornado_filled',

                        'part/finger_tap_filled',
                        'building/store_filled',
                        'building/sydney_opera_house_filled',
                        'part/dental_filled',
                        'business/presentation_2_filled',
                        'transport/flight_takeoff_filled',
                        'business/filter_3_filled',
                        'sport/badminton_filled',
                        'business/shopping_cart_1_filled',
                        'design/quill_pen_filled',
                        'building/hotel_filled',
                        'sport/fitness_filled',
                        'transport/rocket_filled',
                        'system/flame_filled',

                        'business/chart_decrease_filled',
                        'business/chart_pie_2_filled',
                        'business/wallet_3_filled',
                        'business/chart_bar_filled',
                        'system/settings_7_filled',
                        'business/coin_2_filled',
                        'business/ticket_filled',
                        'contact/chat_3_filled',
                        'business/currency_dollar_filled',
                        'business/gift_filled',
                        'contact/mailbox_filled',
                        'contact/phone_outgoing_filled',
                        'contact/send_plane_filled',
                        'contact/mail_open_filled',

                        'crypto/ethereum_filled',
                        'crypto/monero_filled',
                        'design/board_filled',
                        'design/brush_2_filled',
                        'design/drop_filled',
                        'design/layout_3_filled',
                        'design/magic_3_filled',
                        'design/paint_2_filled',
                        'design/scale_filled',
                        'design/scissors_2_filled',
                        'development/cursor_3_filled',
                        'development/department_filled',
                        'device/airplay_filled',
                        'device/alarm_2_filled',

                        'device/dashboard_filled',
                        'device/device_filled',
                        'device/homepod_filled',
                        'weather/snowflake_filled',
                        'device/vison_pro_filled',
                        'editor/at_filled',
                        'editor/pencil_2_filled',
                        'editor/translate_2_filled',
                        'education/mortarboard_filled',
                        'education/black_board_2_filled',
                        'emoji/puzzled_filled',
                        'emoji/tongue_filled',
                        'file/box_3_filled',
                        'file/file_certificate_filled',
                        
                        'file/inbox_2_filled',
                        'file/link_3_filled',
                        'file/pic_filled',
                        'file/task_2_filled',
                        'food/birthday_2_filled',
                        'food/fries_filled',
                        'food/lollipop_filled',
                        'map/location_3_filled',
                        'map/planet_filled',
                        'nature/chess_filled',
                        'nature/tree_filled',
                        'nature/flower_4_filled',
                        'other/capsule_filled',
                        'other/hand_card_filled',
                    ]
                },
                {
                    'name': 'duotone',
                    'array': [
                    'building/home_2_duotone',
                        'file/file_duotone',
                        'file/folder_duotone',
                        'business/calendar_duotone',
                        'business/palette_3_duotone',
                        'building/building_2_duotone',
                        'business/wallet_3_duotone',
                        'business/target_duotone',
                        'design/bling_duotone',
                        'design/pen_duotone',
                        'shape/heart_duotone',
                        'user/user_3_duotone',
                        'shape/clubs_duotone',
                        'weather/tornado_duotone',

                        'part/finger_tap_duotone',
                        'building/store_duotone',
                        'building/sydney_opera_house_duotone',
                        'part/dental_duotone',
                        'business/presentation_2_duotone',
                        'transport/flight_takeoff_duotone',
                        'business/filter_3_duotone',
                        'sport/badminton_duotone',
                        'business/shopping_cart_1_duotone',
                        'design/quill_pen_duotone',
                        'building/hotel_duotone',
                        'sport/fitness_duotone',
                        'transport/rocket_duotone',
                        'system/flame_duotone',

                        'business/chart_decrease_duotone',
                        'business/chart_pie_2_duotone',
                        'business/wallet_3_duotone',
                        'business/chart_bar_duotone',
                        'system/settings_7_duotone',
                        'business/coin_2_duotone',
                        'business/ticket_duotone',
                        'contact/chat_3_duotone',
                        'business/currency_dollar_duotone',
                        'business/gift_duotone',
                        'contact/mailbox_duotone',
                        'contact/phone_outgoing_duotone',
                        'contact/send_plane_duotone',
                        'contact/mail_open_duotone',

                        'crypto/ethereum_duotone',
                        'crypto/monero_duotone',
                        'design/board_duotone',
                        'design/brush_2_duotone',
                        'design/drop_duotone',
                        'design/layout_3_duotone',
                        'design/magic_3_duotone',
                        'design/paint_2_duotone',
                        'design/scale_duotone',
                        'design/scissors_2_duotone',
                        'development/cursor_3_duotone',
                        'development/department_duotone',
                        'device/airplay_duotone',
                        'device/alarm_2_duotone',

                        'device/dashboard_duotone',
                        'device/device_duotone',
                        'device/homepod_duotone',
                        'weather/snowflake_duotone',
                        'device/vison_pro_duotone',
                        'editor/at_duotone',
                        'editor/pencil_2_duotone',
                        'editor/translate_2_duotone',
                        'education/mortarboard_duotone',
                        'education/black_board_2_duotone',
                        'emoji/puzzled_duotone',
                        'emoji/tongue_duotone',
                        'file/box_3_duotone',
                        'file/file_certificate_duotone',
                        
                        'file/inbox_2_duotone',
                        'file/link_3_duotone',
                        'file/pic_duotone',
                        'file/task_2_duotone',
                        'food/birthday_2_duotone',
                        'food/fries_duotone',
                        'food/lollipop_duotone',
                        'map/location_3_duotone',
                        'map/planet_duotone',
                        'nature/chess_duotone',
                        'nature/tree_duotone',
                        'nature/flower_4_duotone',
                        'other/capsule_duotone',
                        'other/hand_card_duotone',
                    ]
                },
                {
                    'name': 'two tone',
                    'array': [
                        'building/home_2_twotone',
                        'file/file_twotone',
                        'file/folder_twotone',
                        'business/calendar_twotone',
                        'business/palette_3_twotone',
                        'building/building_2_twotone',
                        'business/wallet_3_twotone',
                        'business/target_twotone',
                        'design/bling_twotone',
                        'design/pen_twotone',
                        'shape/heart_twotone',
                        'user/user_3_twotone',
                        'shape/clubs_twotone',
                        'weather/tornado_twotone',

                        'part/finger_tap_twotone',
                        'building/store_twotone',
                        'building/sydney_opera_house_twotone',
                        'part/dental_twotone',
                        'business/presentation_2_twotone',
                        'transport/flight_takeoff_twotone',
                        'business/filter_3_twotone',
                        'sport/badminton_twotone',
                        'business/shopping_cart_1_twotone',
                        'design/quill_pen_twotone',
                        'building/hotel_twotone',
                        'sport/fitness_twotone',
                        'transport/rocket_twotone',
                        'system/flame_twotone',

                        'business/chart_decrease_twotone',
                        'business/chart_pie_2_twotone',
                        'business/wallet_3_twotone',
                        'business/chart_bar_twotone',
                        'system/settings_7_twotone',
                        'business/coin_2_twotone',
                        'business/ticket_twotone',
                        'contact/chat_3_twotone',
                        'business/currency_dollar_twotone',
                        'business/gift_twotone',
                        'contact/mailbox_twotone',
                        'contact/phone_outgoing_twotone',
                        'contact/send_plane_twotone',
                        'contact/mail_open_twotone',

                        'crypto/ethereum_twotone',
                        'crypto/monero_twotone',
                        'design/board_twotone',
                        'design/brush_2_twotone',
                        'design/drop_twotone',
                        'design/layout_3_twotone',
                        'design/magic_3_twotone',
                        'design/paint_2_twotone',
                        'design/scale_twotone',
                        'design/scissors_2_twotone',
                        'development/cursor_3_twotone',
                        'development/department_twotone',
                        'device/airplay_twotone',
                        'device/alarm_2_twotone',

                        'device/dashboard_twotone',
                        'device/device_twotone',
                        'device/homepod_twotone',
                        'weather/snowflake_twotone',
                        'device/vison_pro_twotone',
                        'editor/at_twotone',
                        'editor/pencil_2_twotone',
                        'editor/translate_2_twotone',
                        'education/mortarboard_twotone',
                        'education/black_board_2_twotone',
                        'emoji/puzzled_twotone',
                        'emoji/tongue_twotone',
                        'file/box_3_twotone',
                        'file/file_certificate_twotone',
                        
                        'file/inbox_2_twotone',
                        'file/link_3_twotone',
                        'file/pic_twotone',
                        'file/task_2_twotone',
                        'food/birthday_2_twotone',
                        'food/fries_twotone',
                        'food/lollipop_twotone',
                        'map/location_3_twotone',
                        'map/planet_twotone',
                        'nature/chess_twotone',
                        'nature/tree_twotone',
                        'nature/flower_4_twotone',
                        'other/capsule_twotone',
                        'other/hand_card_twotone',
                    ]
                },
            ],
            types: [
                {
                    'name' : 'Cute-li',
                    'isNew': true,
                },
                {
                    'name' : 'Cute-re',
                    'isNew': true,
                },
                {
                    'name' : 'Cute-fi',
                    'isNew': true,
                },
                {
                    'name' : 'Sharp',
                    'isNew': false,
                },
                {
                    'name' : 'Light',
                    'isNew': false,
                },
                {
                    'name' : 'Regular',
                    'isNew': false,
                },
                {
                    'name' : 'filled',
                    'isNew': false,
                },
                {
                    'name' : 'Duotone',
                    'isNew': false,
                },
                {
                    'name' : 'Two tone',
                    'isNew': false,
                },
            ],
            currentTypes: 'Cute-li',
            currentSampleIndex: 0,
            currentIconsIndex: 0,
            rotateAngle: 48,
            translateY: 0,
            lastScrollTop: 0,
            animations: [],
            animationInstance: null,
            flexLottie: [
                {
                    'name': 'home_2_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'camera_2_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'cellphone_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'file_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'currency_bitcoin_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'pencil_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'dashboard_3_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'book_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'bookmark_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'documents_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'heart_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'folder_open_2_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'eye_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'earth_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'notification_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'menu_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'location_line',
                    "loop": false,
                    "autoplay": false,
                },
                {
                    'name': 'scan_line',
                    "loop": false,
                    "autoplay": false,
                }
            ],
            quesionAnswer: [
                {
                    'question': 'Why do I need a Pro icon system?',
                    'answer': 'While numerous icon libraries are available online today, many of them face challenges like low quality, bugs, overly stylized designs, or insufficient sizing. The pro icon system offers a wide selection of high-quality icons with a consistent style, which helps save time and ensures a cohesive and polished look for design projects. It provides versatility, scalability, and attention to detail, enhancing the overall visual appeal and professionalism of your work.',
                    'isOpenQ': false,
                },
                {
                    'question': 'Is there a free plan?',
                    'answer': 'Yes, we offer a free plan that includes 540 icons in the 60 x 9 styles, available for unlimited use with no royalties. You can access the Figma source file for these 540 icons via <a href="https://www.figma.com/community/file/1356275486390593802" target="_blank">the link</a>.',
                    'isOpenQ': false,
                },
                {
                    'question': 'What is the difference between MGC and MingCute?',
                    'answer': 'MGC performs detailed optimization on the basis of MingCute, expanding 9 styles. MGC retains the drawn path instead of merging the curved graphics. You will be able to fully customize the icon rounded corners, line weight, and path anchor point adjustments. MGC has more perfect details, more styles, greater flexibility, and richer customization.',
                    'isOpenQ': false,
                },
                {
                    'question': 'What to do if you miss the icons you need?',
                    'answer': 'Contact us through email, describe the icons you need, and we will include them in the plan to update to the new version, striving to ensure that our users get the icons they need.',
                    'isOpenQ': false,
                },
                {
                    'question': 'Is it a one-time payment?',
                    'answer': 'Yes. There are no hidden costs or recurring payments to worry about. You only need to make a single flat rate payment, which gives you unlimited access to our icon pack.',
                    'isOpenQ': false,
                },
                {
                    'question': 'Does MGC offer a money-back guarantee?',
                    'answer': 'We understand that customer satisfaction is important, and we aim to ensure that our customers are happy with their purchase. However, as the pack is a digital product and a free trial version is provided, we generally do not offer money-back guarantees unless there is a valid reason. If you have any concerns about your purchase, please contact us at <a href="mailto:9394lay@gmail.com" target="_blank">9394lay@gmail.com</a> within 10 days of your purchase, and we will do our best to assist you. Our goal is to provide an excellent customer experience and ensure your satisfaction with MGC.',
                    'isOpenQ': false,
                },
            ],
            lottieOther: [
                {
                    "name": "arrow_right_line",
                    "loop": false,
                    "autoplay": false,
                },
                {
                    "name": "sun_line",
                    "loop": true,
                    "autoplay": true,
                },
                {
                    "name": "voice_line",
                    "loop": true,
                    "autoplay": true,
                }
            ],
            lottieOtherAnimations: [],
            animationInstanceOther: null,
        }
    },
    methods: {
        loadOtherAnimation() {
            this.lottieOther.forEach((item, index) => {
                // const container = document.getElementById(`lottieOther-${item.name}`);
                const container = document.querySelectorAll(`.lottieOther-${item.name}`);
                container.forEach(contItem => {
                    const name = item.name.replace(/_[0-9]+$/, '');

                    const animationConfig = {
                        container: contItem,
                        renderer: 'svg',
                        loop: item.loop,
                        autoplay: item.autoplay,
                        path: `${this.domainName}/images/otherJson/${name}.json`,
                    };

                    this.animationInstanceOther = lottie.loadAnimation(animationConfig);

                    this.lottieOtherAnimations.push(this.animationInstanceOther);
                });

                
            });
        },
        playAnimation(event,name) {
            const allElements = document.getElementsByClassName(`box-${name}`);
            // console.log(`.box-${name}`);

            const currentElement = event.target;

            let thisIndex = Array.from(allElements).indexOf(currentElement);
            // console.log(`这个是页面中出现的第 ${thisIndex} 个lx`);
            
            this.lottieOtherAnimations[thisIndex].playSegments([0, this.lottieOtherAnimations[thisIndex].animationData.op], true);
        },
        pauseAnimation(name) {
            let index = -1;
            for(let i=0; i < this.lottieOther.length; i++) {
                if(this.lottieOther[i].name == name) {
                    index = i;
                    break;
                }
            }
            this.lottieOtherAnimations[index].stop();
        },
        toggleStyles(index, name) {
            this.currentTypes = name;
            this.currentSampleIndex = index;
            this.currentIconsIndex = index;
        },
        toggleQ(index) {
            this.quesionAnswer[index].isOpenQ = !this.quesionAnswer[index].isOpenQ;
        },
        gotoFAQ() {
            const element = document.getElementById('scrollPage');
            const targetElement = document.querySelector("#consult-wrap");
            if(targetElement) {
                const targetOffset = targetElement.offsetTop - 70;
                element.scrollTop = targetOffset;
            }
        },
        gotoPricing() {
            const element = document.getElementById('scrollPage');
            const targetElement = document.querySelector("#pricing-wrap");
            if(targetElement) {
                const targetOffset = targetElement.offsetTop - 70;
                element.scrollTop = targetOffset;
            }
        },
        handleScroll() {
            let container = document.getElementById('scrollPage');
            let scrollHeight = container.scrollTop;

            const windowInner = window.innerHeight

            // 元素顶部距离视口顶部的距离 < 视口高度
            let targetElement = this.$refs.targetElement;
            // const rect = this.$refs.targetElement.getBoundingClientRect();
            // const elementTop = rect.top;
            const elementTop = targetElement.offsetTop - scrollHeight;
            const elementHeight = targetElement.offsetHeight;
            
            // console.log('元素顶部距离视口顶部的距离:' + elementTop);
            // console.log('视口高度' + windowInner);
            // 元素底部距离视口底部的距离 > 0
            // const distanceToBottom = windowInner - rect.bottom;
            const distanceToBottom = windowInner - elementTop - elementHeight;
            // console.log('元素底部距离视口底部的距离:' + distanceToBottom);

            if(elementTop < windowInner && distanceToBottom <= 0) {
                const newAngle = 48 * (elementTop / window.innerHeight);
                if(newAngle < 0) {
                    this.rotateAngle = 0;
                } else {
                    this.rotateAngle = newAngle;
                }
            }
            if(elementTop > windowInner) {
                this.rotateAngle = 48;
            }
            if(distanceToBottom > 0) {
                this.rotateAngle = 0;
            }

            const newtranslateY = 120 * (elementTop / window.innerHeight);
            setTimeout(() => {
                this.translateY = newtranslateY;
            }, 100);

            let isScrolling;

            // 清除之前的定时器
            window.clearTimeout(isScrolling);
            // 在这里处理页面停止滚动后的操作

            isScrolling = setTimeout(function() {
                // console.log('页面停止滚动了！');
                this.translateY = this.translateY * 3.1;
            }, 500);
            // console.log(this.translateY);



            // 判断鼠标是向上滑还是向下滑动
            // const element = document.getElementById('scrollPage');
            // const currentScrollTop = element.scrollTop;

            // if (currentScrollTop > this.lastScrollTop) {
            //     console.log('向下滑动');
            //     this.translateY = -100;
            // } else if (currentScrollTop < this.lastScrollTop) {
            //     console.log('向上滑动');
            //     this.translateY = 100;
            // }

            // this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
        },
        playIndex(item, index) {
            this.animations.forEach(animation => {
                if(item.name === animation.name) {
                    animation.playSegments([0, animation.animationData.op], true);
                }
            });
        },
        destroyAnimation() {
            if (this.animationInstance) {
                console.log('destroying');
                this.animations.forEach(animation => {
                    animation.destroy();
                });
                this.animations = [];
                this.animationInstance = null;
            }
        },
        destroyAnimationOther() {
            if(this.animationInstanceOther) {
                console.log('destroyingOther');
                this.lottieOtherAnimations.forEach(animation => {
                    animation.destroy();
                });
                this.lottieOtherAnimations = [];
                this.animationInstanceOther = null;
            }
        },
    },
    mounted() {
        this.loadOtherAnimation();

        this.flexLottie.forEach(flexLottieItem => {
            let flexLotties = document.querySelectorAll('.flexLottie-' + flexLottieItem.name);
            flexLotties.forEach(svgContainer => {
                const animationConfig = {
                    container: svgContainer,
                    animType: 'svg',
                    loop: false,
                    path: `${this.domainName}/images/flexJson/${flexLottieItem.name}.json`,
                }

                this.animationInstance = lottie.loadAnimation(animationConfig);
                this.animationInstance.name = flexLottieItem.name;
                this.animations.push(this.animationInstance);
            });
        })

        window.addEventListener('resize', this.handleScroll);
        this.handleScroll(); // 初始化时触发一次处理函数
    },
    beforeDestroy() {
        this.destroyAnimation();
        this.destroyAnimationOther();
        window.removeEventListener('resize', this.handleScroll);
    },
});
</script>